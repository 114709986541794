import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Cadastrar novo Plano ")]),_c(VCardText,[_c(VTextField,{staticClass:"mt-4",attrs:{"label":"Nome do plano","dense":"","outlined":"","rules":[_vm.rules.required]},model:{value:(_vm.inputName),callback:function ($$v) {_vm.inputName=$$v},expression:"inputName"}}),_c('div',{staticClass:"d-flex justify-end mt-5"},[_c(VBtn,{attrs:{"color":"info"},on:{"click":_vm.sendModalData}},[(!_vm.loadingCircle)?_c('span',[_vm._v("Enviar")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1),_c(VBtn,{staticClass:"ml-5",attrs:{"color":"error"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }