import { render, staticRenderFns } from "./ModalPlans.vue?vue&type=template&id=3356531f&"
import script from "./ModalPlans.vue?vue&type=script&lang=js&"
export * from "./ModalPlans.vue?vue&type=script&lang=js&"
import style0 from "./ModalPlans.vue?vue&type=style&index=0&id=3356531f&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports