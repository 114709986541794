<template>
  <v-card>
    <v-card-title class="d-flex justify-content-between">
      <span> Planos Cadastrados </span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="openModalPlans">
        <v-icon size="30" class="me-2">
          {{ icons.mdiPlaylistPlus }}
        </v-icon>
        Novo
      </v-btn>
    </v-card-title>
    <v-data-table
      :key="itemsTable.id"
      :headers="headers"
      :items="itemsTable"
      :loading="loading"
      loading-text="Carregando dados..."
      hide-default-footer
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      class="text-no-wrap"
      @page-count="pageCount = $event"
    >
      <template #[`item.price`]="{ item }">
        {{ Number(item.price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ dateFormat(item.created_at) }}
      </template>

      <template #[`item.action`]="{ item }">
        <v-icon medium class="me-2" @click="openPlansDetails(item)">
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
    </v-data-table>

    <v-card-text class="pt-2 pb-1">
      <v-row>
        <v-col lg="12" cols="12" class="d-flex justify-center">
          <v-pagination
            v-model="pageOptions.page"
            total-visible="6"
            :length="pageOptions.pageCount"
            @input="updatePage"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="showModalPlans" width="700px">
      <ModalPlans :updated-table="updatedTable" @close="showModalPlans = false"></ModalPlans>
    </v-dialog>

    <v-dialog v-model="showDetails" width="700px">
      <PlansDetails :data="dataDetails" :update-page="updatePage" @close="showDetails = false"></PlansDetails>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import { mdiPencilOutline, mdiPlaylistPlus } from '@mdi/js'
import ModalPlans from './ModalPlans.vue'
import PlansDetails from './PlansDetails.vue'

export default {
  components: {
    ModalPlans,
    PlansDetails,
  },

  data() {
    return {
      headers: [
        {
          text: 'NOME',
          value: 'name',
          sortable: true,
          align: '',
        },

        {
          text: 'EDITAR',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      dataDetails: {},
      showModalPlans: false,
      showDetails: false,
      loading: false,

      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },

      icons: {
        mdiPlaylistPlus,
        mdiPencilOutline,
      },
    }
  },

  created() {
    this.getItemsTable()
  },
  methods: {
    async getItemsTable() {
      this.loading = true

      await axiosIns.get('/api/app/v1/plan/index').then(res => {
        this.itemsTable = res.data.data
        this.pageOptions.pageCount = res.data.meta.last_page
        this.loading = false
      })
    },

    async updatePage() {
      this.loading = true
      this.itemsTable = []

      await axiosIns.get(`/api/app/v1/plan/index?page=${this.pageOptions.page}`).then(res => {
        this.itemsTable = res.data.data

        this.pageOptions.pageCount = res.data.meta.last_page
        this.loading = false
      })
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModalPlans() {
      this.showModalPlans = true
    },

    // eslint-disable-next-line no-unused-vars
    openPlansDetails(item) {
      this.showDetails = true
      this.dataDetails = item
    },

    dateFormat(date) {
      const dateSliced = date.slice(0, 10)

      const hour = date.slice(11, 19)

      const dateDivided = dateSliced.split('-')

      const dateFormated = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]} - ${hour}`

      return dateFormated
    },

    closeModal() {
      this.showModalPlans = false
    },
  },
}
</script>
