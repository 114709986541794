<template>
  <v-skeleton-loader v-if="skeleton" type="article, actions"></v-skeleton-loader>
  <div v-else>
    <v-card :key="data.id">
      <v-card-title class="justify-center">
        <span>Descrição do veiculo</span>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="data.name"
          :append-icon="icons.mdiPencilOutline"
          label="Nome do Plano"
          class="mt-4"
          outlined
          dense
        ></v-text-field>

        <div class="d-flex justify-end mt-5">
          <v-btn color="info" @click="sendModalValue">
            <span v-if="!loadingCircleSendForm">Enviar</span>
            <v-progress-circular v-else color="white" indeterminate> </v-progress-circular>
          </v-btn>
          <v-btn color="error" class="ml-5" @click="closeModal"> Fechar </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiPencilOutline } from '@mdi/js'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    updatePage: {
      type: Function,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      skeleton: false,
      loadingCircleSendForm: false,

      icons: {
        mdiPencilOutline,
      },
    }
  },
  computed: {},
  created() {
    this.getDataById()
  },

  methods: {
    async getDataById() {
      this.skeleton = true
      await axiosIns.get(`/api/app/v1/plan/edit/${this.data.id}`).then(resp => {
        this.dataDetails = resp.data.data
        this.skeleton = false
      })
    },

    async sendModalValue() {
      this.loadingCircleSendForm = true

      const body = {
        name: this.data.name,
      }

      await axiosIns
        .put(`/api/app/v1/plan/update/${this.data.id}`, body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Cadastro Alterado!',
            showConfirmButton: false,
            timer: 3000,
          })
          this.loadingCircleSendForm = false
          this.updatePage()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })

      this.closeModal()
    },

    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
