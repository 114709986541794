import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.skeleton)?_c(VSkeletonLoader,{attrs:{"type":"article, actions"}}):_c('div',[_c(VCard,{key:_vm.data.id},[_c(VCardTitle,{staticClass:"justify-center"},[_c('span',[_vm._v("Descrição do veiculo")])]),_c(VCardText,[_c(VTextField,{staticClass:"mt-4",attrs:{"append-icon":_vm.icons.mdiPencilOutline,"label":"Nome do Plano","outlined":"","dense":""},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('div',{staticClass:"d-flex justify-end mt-5"},[_c(VBtn,{attrs:{"color":"info"},on:{"click":_vm.sendModalValue}},[(!_vm.loadingCircleSendForm)?_c('span',[_vm._v("Enviar")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1),_c(VBtn,{staticClass:"ml-5",attrs:{"color":"error"},on:{"click":_vm.closeModal}},[_vm._v(" Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }