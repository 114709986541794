import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(" Planos Cadastrados ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"info"},on:{"click":_vm.openModalPlans}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_vm._v(" Novo ")],1)],1),_c(VDataTable,{key:_vm.itemsTable.id,staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.loading,"loading-text":"Carregando dados...","hide-default-footer":"","page":_vm.pageOptions.page,"items-per-page":_vm.pageOptions.itemsPerPage},on:{"update:page":function($event){return _vm.$set(_vm.pageOptions, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.openPlansDetails(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}}],null,true)}),_c(VCardText,{staticClass:"pt-2 pb-1"},[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"lg":"12","cols":"12"}},[_c(VPagination,{attrs:{"total-visible":"6","length":_vm.pageOptions.pageCount},on:{"input":_vm.updatePage},model:{value:(_vm.pageOptions.page),callback:function ($$v) {_vm.$set(_vm.pageOptions, "page", $$v)},expression:"pageOptions.page"}})],1)],1)],1),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showModalPlans),callback:function ($$v) {_vm.showModalPlans=$$v},expression:"showModalPlans"}},[_c('ModalPlans',{attrs:{"updated-table":_vm.updatedTable},on:{"close":function($event){_vm.showModalPlans = false}}})],1),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}},[_c('PlansDetails',{attrs:{"data":_vm.dataDetails,"update-page":_vm.updatePage},on:{"close":function($event){_vm.showDetails = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }