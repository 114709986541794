<template>
  <v-card>
    <v-card-title class="justify-center">
      Cadastrar novo Plano
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="inputName"
        label="Nome do plano"
        class="mt-4"
        dense
        outlined
        :rules="[rules.required]"
      >
      </v-text-field>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="info"
          @click="sendModalData"
        >
          <span
            v-if="!loadingCircle"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
        <v-btn
          color="error"
          class="ml-5"
          @click="closeModal"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiCamera } from '@mdi/js'

export default {
  props: {
    updatedTable: {
      type: Function,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      inputName: '',
      loadingCircle: false,
      icons: {
        mdiCamera,
      },

      rules: {
        required: value => !!value || 'Campo obrigatório.',
      },

    }
  },

  created() {

  },
  methods: {

    async sendModalData() {
      this.loadingCircle = true
      const body = {
        name: this.inputName,
      }

      await axiosIns.post('/api/app/v1/plan/store', body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
            showConfirmButton: false,
            timer: 3000,
          })

          this.updatedTable()
          this.loadingCircle = false
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })

      this.closeModal()
    },

    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
    <style>
      /* Retira o incrementador padrão dos inputs do tipo "number"*/
      input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
      }
      </style>
